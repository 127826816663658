import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <section className="404" id="404">
      <div className="wrapper">
        <div className="container">
          <div className="row justify-content-center mt-5 pt-5">
            <div className="col-md-7 mt-5 text-center">
              <div className="home-description">
                <h1 className="title mb-4">NOT FOUND</h1>
                <p>
                  You just hit a route that doesn&#39;t exist... the sadness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
